<template>
  <div class="ws-language">
    <MPopover
      :position="position"
      class-name="language__popover"
      @open="onOpen"
    >
      <template #activator>
        <button :class="rootClass">
          <NuxtLink
            v-if="localeLink"
            :to="localeLink"
            :class="`i-language-${locale}`"
            external
            @click.prevent
          >
            {{ locale }}
          </NuxtLink>
          <span v-else :class="`i-language-${locale}`">{{ locale }}</span>
        </button>
      </template>

      <template #content>
        <MListItem
          v-for="item in langList"
          :key="item.code"
          :icon-name="item.iconName"
          @click="selectLanguage(item.code)"
        >
          {{ item.title }}
        </MListItem>
      </template>
    </MPopover>
  </div>
</template>

<script lang="ts" setup>
import './language.css'
import { defineComponent, computed, ref } from 'vue'
import { useLocalization } from '#imports'
import { useConfig } from '@/stores/config'
import { useLocal } from '@/composables/locale'
import MPopover from '@/components/molecules/Popover/MPopover.vue'
import MListItem from '@/components/molecules/ListItem/MListItem.vue'

import type { PropType } from 'vue'
import type { IconName } from '@/utils/icon-types'
import type { LocaleType, CountryCodeType } from '@/types/locale.d'
import type { PopoverPosition } from '@/components/molecules/Popover/types'

export type LangItem = {
  iconName: IconName
  code: LocaleType | CountryCodeType
  title: string
}

defineComponent({ name: 'WsLanguageSwitch' })

defineProps({
  position: {
    type: String as PropType<PopoverPosition>,
    default: 'bottom-end'
  }
})

const { setLocale, switchLocalePath } = useLocal()
const { locale } = useLocalization()
const { currentRegion } = useConfig()

const langTitleDct: Record<string, string> = {
  ru: 'Русский - RU',
  en: 'English - EN',
  uk: 'Ukraine - UA',
  kz: 'Kazakhstan - KZ'
}

const rootClass = computed(() => {
  const list = ['language']

  return list.join(' ')
})

const localeLink = computed(() => {
  const localeToChange = currentRegion.value?.allowLocalesList?.find?.(l => l !== locale.value)

  if (localeToChange) {
    return switchLocalePath(localeToChange)
  }

  return ''
})

const langList = ref<LangItem[]>([])

function onOpen () {
  if (langList.value.length) { return }

  if (currentRegion.value) {
    langList.value = currentRegion.value?.allowLocalesList
      ?.map(el => transformCountryToLang(el.slice(0, 2) as LocaleType))
  }
}

const getIconNameByCode = (code: LocaleType | CountryCodeType): IconName => {
  return (code === 'uk' || code === 'en') ? 'gb' : code
}

const transformCountryToLang = (code: LocaleType | CountryCodeType): LangItem => ({
  iconName: getIconNameByCode(code),
  code,
  title: langTitleDct[code]
})

const selectLanguage = (code: LocaleType | CountryCodeType): void => {
  const langIdx = langList.value.findIndex(el => el.code === code)

  if (langIdx === -1) { return }

  setLocale(code as LocaleType)
}
</script>
