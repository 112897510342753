import { computed } from 'vue'
import { useLocalization } from '#imports'
import { useConfig } from '@/stores/config'

import type { LocaleType, CountryCodeType } from '@/types/locale.d'
import type { Region } from '@/modules/nuxt-api/models/Region'
// import type { Link } from '@unhead/schema'

export function useLocal () {
  const router = useRouter()
  const route = useRoute()
  const host = useRequestURL().host
  const protocol = useRequestURL().protocol

  const { locale, fallbackLocale } = useLocalization()
  const { currentRegion, testRegionSubdomain } = useConfig()

  const prefixRegex = /^\/(en|ru|uk|kz)(\/|$)/i

  const getSubdomain = (l: string | LocaleType): string => {
    if (l === locale.value) { return '' }
    if (l === 'ru') { return '' }
    if (l === 'en') { return 'eng.' }
    return `${l}.`
  }

  const countryCode = computed(() => currentRegion.value?.country?.code)
  const isExternalLocaleLink = computed(() => currentRegion.value?.localeCodeType === 1)

  const getClearHost = (withDomain = false): string | undefined => {
    const splitHost = host?.split('.') ?? []

    return splitHost.filter(el =>
      el !== currentRegion.value?.subdomain &&
      el !== testRegionSubdomain.value &&
      el !== 'eng' &&
      (!withDomain || !currentRegion.value?.domain?.includes(el))
    ).join('.')
  }

  const getCountryHost = (country: string | CountryCodeType, countries: Region[]): string | undefined => {
    let domain = ''

    if (country === countryCode.value) {
      domain = currentRegion.value?.domain ?? ''
    } else {
      const countryIdx = countries.findIndex(item => item.country?.code === country) || -1
      if (!countries || countryIdx === -1) { return }

      domain = countries[countryIdx].domain
    }

    const fullDomain = country === 'eu' ? `online.${domain}` : `.${domain}`
    const host = country !== 'ru' ? 'winestyle' : (getClearHost(true) ?? '')

    if (host.length === 0) { return }
    if (host.includes('localhost')) { return host }

    return `${host}${fullDomain}`
  }

  const checkAndRouteToExternalDomain = (l: LocaleType | string): boolean | undefined => {
    if (!isExternalLocaleLink.value) { return }
    if (!process.server) {
      window.open(switchLocalePath(l ?? locale), '_self')
    }
    return true
  }

  const setCountry = (countries: Region[], country?: CountryCodeType): void => {
    if (country === undefined || country === countryCode.value) { return }

    const host: string | undefined = getCountryHost(country, countries)
    if (host === undefined) { return }

    const fullPath = `${protocol}//${host}${route.fullPath.replace(prefixRegex, '/')}`
    window.open(fullPath, '_self')
  }

  const setLocale = async (newLocale: string) => {
    const { setLocale } = useLocalization()

    if (locale.value !== newLocale && newLocale) {
      if (!checkAndRouteToExternalDomain(newLocale)) {
        const updatedLocale = newLocale || locale.value

        await setLocale(updatedLocale)
        await router.push(switchLocalePath(newLocale))
      }
    }
  }

  const switchLocalePath = (l: string) => {
    const host = getClearHost() ?? ''

    if (isExternalLocaleLink.value && l) {
      const subdomain = getSubdomain(l)
      return `${protocol}//${subdomain}${host}${route.fullPath.replace(prefixRegex, '/')}`
    }

    let path = route.path
    if (!l) { return path }

    const prefix = ['ru']

    if (l === fallbackLocale.value) {
      path = prefix?.[0] ? path.replace(prefix[0], '/') : path
    } else {
      path = prefix?.[0]
        ? path.replace(prefix[0], '')
        // ? path.replace(prefix[0], `/${l}/`)
        : `/${path}`
      // : `/${l}${path}`
    }

    return path
  }

  // const getLocalizedCountryPathList = (config: Region, countries: Region[], withDefault = false): Array<Record<string, string>> => {
  //   if (config.country?.code === undefined) { return [] }

  //   const host = getCountryHost(config.country.code, countries) ?? ''
  //   const pathList: Link[] = []

  //   for (const l of config.allowLocalesList) {
  //     let subdomain = ''
  //     let path = route.fullPath.replace(prefixRegex, '/')

  //     if (config.localeCodeType === 1 && l) {
  //       subdomain = l !== config.domain ? l === 'en' ? 'eng.' : `${l}.` : ''
  //     } else if (l !== config.defaultLocale) {
  //       path = `/${l}${path}`
  //     }

  //     if (withDefault && config.defaultLocale === l) {
  //       pathList.push({
  //         rel: 'alternate',
  //         hreflang: 'x-default',
  //         href: `${protocol}//${subdomain}${host}${path}`
  //       })
  //     }

  //     pathList.push({
  //       rel: 'alternate',
  //       hreflang: `${l === 'kz' ? 'kk' : l}-${config.country.code}`,
  //       href: `${protocol}//${subdomain}${host}${path}`
  //     })
  //   }

  //   return pathList
  // }

  return {
    setCountry,
    setLocale,
    switchLocalePath
    // getLocalizedCountryPathList
  }
}
