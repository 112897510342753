import { useContextData } from '#imports'
import type { BlogCard } from '@/components/molecules/BlogCard/types'

export function useBlog () {
  const articles = useContextData<BlogCard[]>('articles', [
    {
      user: {
        name: '[MOCKED] Ирина Захарова',
        avatar: 'https://via.placeholder.com/100x100',
        verified: true
      },
      title: '[MOCKED] 10 советов по выбору вина',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/1_336x440.jpg',
      mobileSrc: '/assets/images/blog/1_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: true,
      link: '/articles/content',
      id: 1
    },
    {
      user: {
        name: '[MOCKED] Павел Игнатьев',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] Съешь сам: подбираем вина к традиционным завтракам разных стран ',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/2_336x440.jpg',
      mobileSrc: '/assets/images/blog/2_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 2
    },
    {
      user: {
        name: '[MOCKED] Александр И.',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] Хересная палитра: от сухого до сладкого',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/3_336x440.jpg',
      mobileSrc: '/assets/images/blog/3_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 3
    },
    {
      user: {
        name: 'Александр И.',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] Хересная палитра: от сухого до сладкого',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/2_336x440.jpg',
      mobileSrc: '/assets/images/blog/2_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 4
    },
    {
      user: {
        name: '[MOCKED] Александр И.',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] Хересная палитра: от сухого до сладкого',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/2_336x440.jpg',
      mobileSrc: '/assets/images/blog/2_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      link: '/articles/content',
      id: 5
    },
    {
      user: {
        name: '[MOCKED] Анастасия Леонова',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] 5 лучших красных на НГ',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/2_336x440.jpg',
      mobileSrc: '/assets/images/blog/2_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 6
    },
    {
      user: {
        name: '[MOCKED] Ирина Захарова',
        avatar: 'https://via.placeholder.com/100x100',
        verified: true
      },
      title: '[MOCKED] 10 советов по выбору вина',
      category: 'Вино',
      src: '/assets/images/blog/1_336x440.jpg',
      mobileSrc: '/assets/images/blog/1_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: true,
      link: '/articles/content',
      id: 7
    },
    {
      user: {
        name: '[MOCKED] Павел Игнатьев',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] Вина для холодного сезона',
      category: 'Вино',
      src: '/assets/images/blog/2_336x440.jpg',
      mobileSrc: '/assets/images/blog/2_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 8
    },
    {
      user: {
        name: '[MOCKED] Александр И.',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] Хересная палитра: от сухого до сладкого',
      category: 'Вино',
      src: '/assets/images/blog/2_336x440.jpg',
      mobileSrc: '/assets/images/blog/2_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 9
    },
    {
      user: {
        name: 'Александр И.',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: 'Хересная палитра: от сухого до сладкого',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/2_336x440.jpg',
      mobileSrc: '/assets/images/blog/2_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 10
    },
    {
      user: {
        name: '[MOCKED] Александр И.',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] Хересная палитра: от сухого до сладкого',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/2_336x440.jpg',
      mobileSrc: '/assets/images/blog/2_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 11
    },
    {
      user: {
        name: '[MOCKED] Анастасия Леонова',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] 5 лучших красных на НГ',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/3_336x440.jpg',
      mobileSrc: '/assets/images/blog/3_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 12
    },
    {
      user: {
        name: '[MOCKED] Александр И.',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] Хересная палитра: от сухого до сладкого',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/3_336x440.jpg',
      mobileSrc: '/assets/images/3_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 13
    },
    {
      user: {
        name: '[MOCKED] Александр И.',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] Хересная палитра: от сухого до сладкого',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/3_336x440.jpg',
      mobileSrc: '/assets/images/3_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 14
    },
    {
      user: {
        name: '[MOCKED] Александр И.',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] Хересная палитра: от сухого до сладкого',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/3_336x440.jpg',
      mobileSrc: '/assets/images/3_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 15
    },
    {
      user: {
        name: '[MOCKED] Александр И.',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] Хересная палитра: от сухого до сладкого',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/3_336x440.jpg',
      mobileSrc: '/assets/images/3_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 16
    },
    {
      user: {
        name: '[MOCKED] Александр И.',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] Хересная палитра: от сухого до сладкого',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/3_336x440.jpg',
      mobileSrc: '/assets/images/3_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 17
    },
    {
      user: {
        name: '[MOCKED] Александр И.',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] Хересная палитра: от сухого до сладкого',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/3_336x440.jpg',
      mobileSrc: '/assets/images/3_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 18
    },
    {
      user: {
        name: '[MOCKED] Александр И.',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] Хересная палитра: от сухого до сладкого',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/3_336x440.jpg',
      mobileSrc: '/assets/images/3_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 19
    },
    {
      user: {
        name: '[MOCKED] Анастасия Леонова',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] 5 лучших красных на НГ',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/3_336x440.jpg',
      mobileSrc: '/assets/images/3_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 20
    },
    {
      user: {
        name: '[MOCKED] Анастасия Леонова',
        avatar: 'https://via.placeholder.com/100x100'
      },
      title: '[MOCKED] 5 лучших красных на НГ',
      category: '[MOCKED] Вино',
      src: '/assets/images/blog/3_336x440.jpg',
      mobileSrc: '/assets/images/3_192x320.jpg',
      seen: '[MOCKED] 1 845',
      date: '[MOCKED] 20.12.2021',
      saved: false,
      link: '/articles/content',
      id: 21
    }
  ])

  function getBlogCards () {
    return articles.value
  }

  function getArticles () {
    return ['wine', 'champagne', 'whisky']
  }

  return {
    getBlogCards,
    getArticles
  }
}
