import { useContextData } from '#imports'
import type { Ref, UnwrapRef, WatchSource } from 'vue'
import { getCurrentInstance, onServerPrefetch, ref, toValue } from 'vue'

type HydrationData<T> = {
  data: Ref<UnwrapRef<T>>
  pending: Ref<boolean>
  error: Ref<boolean>
  refresh: () => Promise<any>
}

type HydrationOptions<T> = {
  default?: any
  lazy?: boolean
  server?: boolean
  then?: (data: HydrationData<T>) => any
  catch?: (error: any) => any
  watch?: (WatchSource<unknown> | object)[]
}

export function useHydrationData<T> (
  key: string,
  callback: (() => Promise<T>) | (() => T),
  options?: HydrationOptions<T>
): Promise<HydrationData<T>> {
  const nuxt = useNuxtApp()
  const asyncDataPromises = useContextData<any>('async-data-promises', {})
  const data = ref<T>(undefined as any)
  const pending = ref(false)
  const error = ref(false)
  const onlyClient = options?.server === undefined ? false : !options?.server

  // console.log(asyncDataPromises.value)

  function getDefaultValue () {
    if (typeof options?.default === 'function') {
      return options?.default()
    }

    return unref(options?.default)
  }

  const handler = (import.meta.client || !import.meta.prerender || !nuxt.ssrContext?._sharedPrerenderCache)
    ? callback
    : () => nuxt.runWithContext(callback)

  const refresh = () => {
    pending.value = true

    if (asyncDataPromises.value[key]) {
      return asyncDataPromises.value[key]
    }

    async function _promise () {
      if (nuxt.payload.data[key]) {
        data.value = nuxt.payload.data[key]

        return { data, pending, error, refresh }
      }

      try {
        const result = await handler()

        data.value = toValue(result) as UnwrapRef<T>

        if (data.value) {
          nuxt.payload.data[key] = data.value
        }

        return { data, pending, error, refresh }
      } catch (err: any) {
        error.value = err
        data.value = getDefaultValue()

        throw err
      }
    }

    let promise = _promise()

    promise = promise.finally(() => {
      pending.value = false

      asyncDataPromises.value[key] = undefined
    })

    asyncDataPromises.value[key] = promise

    return asyncDataPromises.value[key]
  }

  if (options?.watch?.length) {
    if ((process.server && !onlyClient) || process.client) {
      const stop = watch(options.watch, () => refresh())
      onBeforeUnmount(() => stop())
    }
  }

  if (process.server) {
    if (!onlyClient) {
      let promise = refresh()

      if (options?.then) {
        promise = promise.then(options.then)
      }

      if (options?.catch) {
        promise = promise.catch(options.catch)
      }

      if (getCurrentInstance()) {
        onServerPrefetch(() => promise)
      } else {
        nuxt.hook('app:created', async () => { await promise })
      }
    }

    return Promise.resolve(asyncDataPromises.value[key])
  }

  if (process.client) {
    const instance = getCurrentInstance()
    const promise = () => {
      let promise = refresh()

      if (options?.then) {
        promise = promise.then(options.then)
      }

      if (options?.catch) {
        promise = promise.catch(options.catch)
      }

      return promise
    }

    if (instance && !instance._nuxtOnBeforeMountCbs) {
      instance._nuxtOnBeforeMountCbs = []
      const cbs = instance._nuxtOnBeforeMountCbs
      onBeforeMount(() => {
        cbs.forEach((cb) => { cb() })
        cbs.splice(0, cbs.length)
      })
      onUnmounted(() => cbs.splice(0, cbs.length))
    }

    if (options?.lazy && instance && !nuxt.isHydrating) {
      instance._nuxtOnBeforeMountCbs.push(() => promise())
    } else {
      promise()
    }
  }

  return new Promise(resolve => resolve({ data, pending, error, refresh }))
}
