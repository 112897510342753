<template>
  <div
    class="list-item"
    :class="{
      active,
      disabled,
      [`i-${iconName}`]: iconName !== undefined,
      [`icon-size-${iconSize}`]: iconSize !== 'base',
      'list-item__title': slots.default
    }"
  >
    <slot v-if="slots.default" />
    <template v-else>
      <div
        v-if="slots['left-element']"
        class="list-item__left-element"
      >
        <slot name="left-element" />
      </div>
      <div class="list-item__text">
        <div
          v-if="slots.title"
          class="list-item__title"
        >
          <slot name="title" />
        </div>
        <div
          v-if="slots.secondary"
          class="list-item__secondary"
        >
          <slot name="secondary" />
        </div>
        <div
          v-if="slots.tertiary"
          class="list-item__tertiary"
        >
          <slot name="tertiary" />
        </div>
      </div>
      <div
        v-if="slots['right-element']"
        class="list-item__right-element"
      >
        <slot name="right-element" />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { IconName } from '@/utils/icon-types'
import type { IconSize } from '@/utils/icon-types-static'

export default defineComponent({
  name: 'MListItem',
  props: {
    iconName: {
      type: String as PropType<IconName>,
      default: undefined
    },
    iconSize: {
      type: String as PropType<IconSize>,
      default: 'base'
    },
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup (_props, { slots }) {
    return { slots }
  }
})
</script>

<style lang="postcss">
.list-item {
  --list-item-tertiary: var(--color-text-dark);
  --list-item-title: var(--color-text-dark);

  display: flex;
  flex-direction: row;
  padding: var(--spacer-xs);
  border-radius: var(--border-radius-xs);
  color: var(--color-text-dark);
  cursor: pointer;

  & .social::before {
    height: 16px;
  }

  &::before,
  &__left-element {
    flex-shrink: 0;
    margin-right: var(--spacer-2xs);
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__tertiary {
    color: var(--list-item-tertiary);

    @mixin text-sm;
  }

  & .list-item__right-element {
    align-self: center;
    margin-left: auto;
  }

  @media (--screen-xs) {
    user-select: none;
  }
}

.list-item.active {
  --list-item-tertiary: var(--primary);
  --list-item-title: var(--primary);

  background-color: var(--primary-focus-light);

  .list-item__text .heading {
    color: var(--primary);
  }
}

.list-item.disabled {
  --list-item-tertiary: var(--color-neutral-500);
  --list-item-title: var(--color-neutral-500);

  color: var(--color-neutral-500);
  cursor: default;
}

.list-item.icon-size-lg::before {
  width: 2rem;
  height: 2rem;
}

.list-item.icon-size-sm::before {
  width: 1.25rem;
  height: 1.25rem;
}

.list-item.icon-size-xs::before {
  width: 1rem;
  height: 1rem;
}

.list-item__title {
  color: var(--list-item-title);

  .fw-n {
    color: var(--color-blue-600);
  }

  @mixin text-base-bold;
}

@media (hover: hover) and (--screen-lg) {
  .list-item:not(.disabled):hover {
    background-color: var(--primary-focus-light);
  }
}
</style>
