<template>
  <Skeletor
    :circle="circle"
    :width="width"
    :height="height"
    :class="`skeleton--br-${size}`"
  />
</template>

<script lang="ts">
import { Skeletor } from 'vue-skeletor'
import 'vue-skeletor/dist/vue-skeletor.css'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

type BorderSize = 'base' | 'xs' | 'lg' | 'xl' | 'full'

export default defineComponent({
  name: 'ASkeleton',
  components: { Skeletor },
  props: {
    width: {
      type: [Number, String],
      default: ''
    },
    height: {
      type: [Number, String],
      default: ''
    },
    circle: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<BorderSize>,
      default: 'base'
    }
  }
})
</script>

<style>
.vue-skeletor--text {
  vertical-align: initial;
}

.skeleton--br-base {
  border-radius: var(--border-radius-base);
}

.skeleton--br-xs {
  border-radius: var(--border-radius-xs);
}

.skeleton--br-xl {
  border-radius: var(--border-radius-xl);
}

.skeleton--br-full {
  border-radius: var(--border-radius-full);
}
</style>
