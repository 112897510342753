import { useNuxtApp } from '#app'
import { useAPI, useCacheData, toRef, useSharedPromise, useHydrationStore, useContextData } from '#imports'

import type { Shop, ShopDrivingDirection } from '@/modules/nuxt-api/models/Shop'
import type { ShopMetadata } from '@/modules/nuxt-api/models/Metadata'

type ModalShopOptions = {
  pickup: boolean
  button?: boolean
  zoom: number
  shops: Shop[]
  initShop: any
  type: 'list' | 'map'
  coordinates: number[]
}

type State = {
  shopListData: Shop[]
  currentShop: Shop | null
  shopMetadata: ShopMetadata | null | undefined
  shopInfoFSRAR: string[][] | null
  shopDirections: Record<string, ShopDrivingDirection[]>
}

export function useShopStore () {
  const nuxtApp = useNuxtApp()
  const api = useAPI()
  const state = useHydrationStore<State>('shop-store', {
    shopListData: [],
    currentShop: null,
    shopMetadata: null,
    shopInfoFSRAR: null,
    shopDirections: {}
  })

  const modalShopOptions = useContextData<ModalShopOptions>('modal-shop-options', {
    pickup: false,
    zoom: 11,
    shops: [],
    initShop: null,
    type: 'map',
    coordinates: []
  })

  const mapSettings = useHydrationStore<Pick<ModalShopOptions, 'zoom' | 'coordinates'>>('map-settings', {
    zoom: 11,
    coordinates: []
  })

  async function fetchShops (overrideRegion?: boolean) {
    const { value, addToCache } = await useCacheData<Shop[]>([
      'shops',
      overrideRegion ? '-region' : ''
    ].filter(Boolean))

    if (value) {
      setData(value)
      return state.value.shopListData
    } else {
      const shops = await nuxtApp.runWithContext(() => useSharedPromise('shops', () => {
        const { getShops } = api.shop()
        return getShops(overrideRegion ?? false)
      }))

      if (shops?.length) {
        setData(shops)
        await addToCache(shops)
      }
    }

    return state.value.shopListData
  }

  async function getShops (overrideRegion?: boolean) {
    if (state.value.shopListData.length === 0) {
      await fetchShops(overrideRegion)
    }

    return state.value.shopListData
  }

  async function getShop (code: string) {
    if (state.value.currentShop && state.value.currentShop.code === code) { return state.value.currentShop }

    const { value, addToCache } = await useCacheData<Shop>(['shop', code])

    if (value) {
      state.value.currentShop = value
    } else {
      const shop = await nuxtApp.runWithContext(() => useSharedPromise(['shop', code], () => {
        const { getShop } = api.shop()
        // [FIXME] Поправить параметры когда разберемся с запросом
        return getShop(code)
      }))

      if (shop) {
        state.value.currentShop = shop
        await addToCache(shop)
      }
    }

    return state.value.currentShop
  }

  async function getShopMetadata (code: string) {
    const { value, addToCache } = await useCacheData<ShopMetadata>(['shop-metadata', code])

    if (value) {
      state.value.shopMetadata = value
    } else {
      const { getShopMetadata } = api.shop()
      state.value.shopMetadata = await nuxtApp.runWithContext(() => getShopMetadata(code))

      if (state.value.shopMetadata) {
        await addToCache(state.value.shopMetadata)
      }
    }

    return state.value.shopMetadata
  }

  async function getInfoForFSRAR (shopCodeList: string[]) {
    if (state.value.shopInfoFSRAR) { return state.value.shopInfoFSRAR }

    const { value, addToCache } = await nuxtApp.runWithContext(() => useCacheData<string[][]>(['requisites', ...shopCodeList]))

    if (value) {
      return value
    } else {
      const { getInfoForFSRAR } = api.shop()
      const requisitesForFSRAR = await nuxtApp.runWithContext(() => getInfoForFSRAR(shopCodeList))

      if (requisitesForFSRAR?.length) {
        await addToCache(requisitesForFSRAR)
        state.value.shopInfoFSRAR = requisitesForFSRAR
      }

      return state.value.shopInfoFSRAR
    }
  }

  async function getShopDrivingDirections (code: string) {
    if (state.value.shopDirections[code]) { return state.value.shopDirections[code] }

    const { value, addToCache } = await useCacheData<ShopDrivingDirection[]>(['shop-directions', code])

    if (value) {
      return value
    } else {
      const shopDirections = await nuxtApp.runWithContext(() => useSharedPromise(['shop-directions', code], async () => {
        const { getShopDrivingDirections } = api.shop()
        const directions = await getShopDrivingDirections(code)
        return directions ? directions.filter(el => el.variantsList.length > 0) : []
      }))

      state.value.shopDirections[code] = shopDirections ?? []

      if (shopDirections?.length) {
        await addToCache(shopDirections)
      }

      return shopDirections ?? []
    }
  }

  function clearShopState () {
    state.value.currentShop = null
    state.value.shopMetadata = null
  }

  function setDefaultModalShopOptions () {
    modalShopOptions.value.button = true
    modalShopOptions.value.pickup = false
    modalShopOptions.value.initShop = null
    modalShopOptions.value.shops = []
  }

  function openShopModal (args?: Partial<ModalShopOptions>) {
    const { openModal } = useModals()
    settings()

    setDefaultModalShopOptions()

    if (args) {
      Object.assign(modalShopOptions.value, args)
    }

    openModal('shop-select')
  }

  function setPickUpShop (shop: Shop) {
    state.value.currentShop = shop
  }

  function setData (data: Shop[]) {
    settings()
    state.value.shopListData = data
  }

  function settings () {
    modalShopOptions.value.coordinates = mapSettings.value.coordinates
    modalShopOptions.value.zoom = mapSettings.value.zoom
  }

  return {
    getShops,
    getShop,
    getShopMetadata,
    getShopDrivingDirections,
    clearShopState,
    getInfoForFSRAR,
    setDefaultModalShopOptions,
    openShopModal,
    setPickUpShop,
    setData,
    settings,
    shopListData: toRef(state.value, 'shopListData'),
    currentShop: toRef(state.value, 'currentShop'),
    shopMetadata: toRef(state.value, 'shopMetadata'),
    modalShopOptions,
    mapSettings
  }
}
