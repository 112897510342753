<template>
  <div class="ws-language">
    <MPopover
      :position="position"
      class-name="language__popover"
      @open="onOpen"
    >
      <template #activator>
        <div class="language__text">
          {{ $t(`other.country.${currentRegion?.country?.code}`) }}
        </div>
      </template>

      <template #content>
        <template v-if="langList.length === 0">
          <div
            v-for="i in 4"
            :key="i"
            class="list-item"
          >
            <ASkeleton width="170px" height="20px"/>
          </div>
        </template>
        <MListItem
          v-for="item in langList"
          :key="item.code"
          :icon-name="item.iconName"
          @click="selectLanguage(item.code)"
        >
          {{ item.title }}
        </MListItem>
      </template>
    </MPopover>
  </div>
</template>

<script lang="ts" setup>
import './language.css'
import { defineComponent, ref } from 'vue'
import { useLocalization } from '#imports'
import { useConfig } from '@/stores/config'
import { useLocal } from '@/composables/locale'
import MPopover from '@/components/molecules/Popover/MPopover.vue'
import MListItem from '@/components/molecules/ListItem/MListItem.vue'
import ASkeleton from '@/components/atoms/Skeleton/ASkeleton.vue'

import type { PropType } from 'vue'
import type { IconName } from '@/utils/icon-types'
import type { LocaleType, CountryCodeType } from '@/types/locale.d'
import type { PopoverPosition } from '@/components/molecules/Popover/types'
import type { Region } from '@/modules/nuxt-api/models/Region'

export type LangItem = {
  iconName: IconName
  code: LocaleType | CountryCodeType
  title: string
}

defineComponent({ name: 'WsCountrySwitch' })

defineProps({
  position: {
    type: String as PropType<PopoverPosition>,
    default: 'bottom-end'
  }
})

const { setCountry } = useLocal()
const { t } = useLocalization()
const { currentRegion, getCountries } = useConfig()

const langList = ref<LangItem[]>([])
const countries = ref<Region[]>([])

async function onOpen () {
  if (langList.value.length) { return }

  countries.value = await getCountries()

  langList.value = countries.value
    .map(el => transformCountryToLang(el.country?.code.slice(0, 2) as CountryCodeType))
}

function getIconNameByCode (code: LocaleType | CountryCodeType): IconName {
  return (code === 'uk' || code === 'en') ? 'gb' : code
}

function transformCountryToLang (code: LocaleType | CountryCodeType): LangItem {
  return {
    iconName: getIconNameByCode(code),
    code,
    title: t(`other.country.${code}`)
  }
}

function selectLanguage (code: LocaleType | CountryCodeType): void {
  setCountry(countries.value, code as CountryCodeType)
}
</script>
