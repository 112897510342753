import type { PropType } from 'vue'
import { defineComponent, useSlots, h, getCurrentInstance, onErrorCaptured } from 'vue'
import { ssrRenderSlotInner } from 'vue/server-renderer'
import { useNuxtApp } from '#app'
import { useSharedPromise } from '#imports'
import { relativeKey } from '@/utils/nitro/keygen'
import { isValueEmpty } from '@/utils/check-empty-value'

function isPromise (p: any) {
  if (typeof p === 'object' && typeof p.then === 'function') {
    return true
  }

  return false
}

async function unrollBuffer (buffer: any[]) {
  let ret = ''
  for (let i = 0; i < buffer.length; i++) {
    let item = buffer[i]
    if (isPromise(item)) {
      item = await item
    }
    if (typeof item === 'string') {
      ret += item
    } else {
      ret += await unrollBuffer(item)
    }
  }
  return ret
}

export function renderSlot (slots: any, parent: any): Promise<string> {
  // Set up buffer and push method. Ideally we could use
  // vue/server-renderer's "createBuffer" method, but unfortunately it
  // isn't exported.
  const buffer: any[] = []
  const push = (v: any) => {
    buffer.push(v)
  }

  // Render the contents of the default slot. We pass in the push method
  // that will mutate our buffer array and add items to it.
  ssrRenderSlotInner(
    // Slots of this wrapper component.
    slots,
    // Chose the default slot.
    'default',
    // Slot props are not supported.
    {},
    // No fallback render function.
    null,
    // Method that pushes markup fragments or promises to our buffer.
    push,
    // This wrapper component's parent.
    parent
  )

  // The buffer now contains a nested array of strings or promises. This
  // method flattens the array down to a single string.
  return unrollBuffer(buffer)
}

function getComponentName (vnode: any): string | undefined {
  if (typeof vnode.type === 'object') {
    if ('__name' in vnode.type) {
      return vnode.type.__name
    } else if ('name' in vnode.type) {
      return vnode.type.name
    }
  }
}

export default defineComponent({
  name: 'RenderCacheable',

  props: {
    tag: {
      type: String,
      default: 'div'
    },

    noCache: {
      type: Boolean,
      default: false
    },

    cacheKey: {
      type: String,
      default: ''
    },

    cacheTags: {
      type: Array as PropType<string[]>,
      default: () => []
    },

    maxAge: {
      type: Number,
      default: undefined
    },

    asyncDataKeys: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },

  async setup (props) {
    const slots = useSlots()
    const currentInstance = getCurrentInstance()
    const nuxtApp = useNuxtApp()

    if (!slots.default) {
      return () => ''
    }

    const defaultSlot = slots.default()
    const name = props.cacheKey || getComponentName(defaultSlot[0]) as string

    function renderSlotToString (parent: any): Promise<any> {
      return new Promise((resolve, reject) => {
        onErrorCaptured((error) => {
          reject(error)
        }, parent)

        renderSlot(slots, parent)
          .then(data => resolve(data))
      })
    }

    if (import.meta.server) {
      let innerHTML = ''

      // eslint-disable-next-line no-useless-catch
      try {
        const sharedKey = relativeKey([name, ...props.cacheTags])

        await useSharedPromise(sharedKey, async () => {
          const cached = await useCacheData<{ data: string, payload: any, expires: number }>([
            name,
            ...props.cacheTags
          ], true)

          if (cached.value) {
            const { data, payload } = cached.value

            if (data) {
              innerHTML = data
            }

            if (payload) {
              Object.keys(payload).forEach((key) => {
                nuxtApp.payload.data[key] = payload[key]
              })
            }
          } else if (currentInstance) {
            const data = await renderSlotToString(currentInstance.parent)
            const expires = props.maxAge
            const payload: Record<string, any> = props.asyncDataKeys.reduce<Record<string, string>>((acc, key) => {
              acc[key] = nuxtApp.payload.data[key]
              return acc
            }, {})

            innerHTML = data

            if (
              props.asyncDataKeys.length
                ? !props.asyncDataKeys.some(key => isValueEmpty(payload[key]))
                : true
            ) {
              await cached.addToCache({
                data,
                payload,
                expires
              }, expires)
            }
          }
        })
      } catch (err) {
        throw err
      }

      return () => h(props.tag, {
        innerHTML
      })
    }

    return () => h(props.tag, slots.default ? slots.default() : [])
  }
})
