<template>
  <div class="footer-checkout">
    <div class="footer-checkout__content">
      <div class="copyright-text">
        &#169; WineStyle 2006 — 2024
      </div>
      <div class="socials-list">
        <AIconButton
          v-for="social in socials"
          :key="social.icon"
          :icon-name="social.icon"
          @click="goToSocial(social.href)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import AIconButton from '@/components/atoms/IconButton/AIconButton.vue'
import { useFooter } from '@/composables/footer'

defineComponent({ name: 'OFooterCheckout' })

const { getSocialLinks } = useFooter()

const socials = getSocialLinks()

const goToSocial = (href: string): void => {
  window.open(href, '_blank')?.focus()
}
</script>

<style lang="postcss">
.footer-checkout {
  background: var(--color-neutral-900);

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 92rem;
    margin: auto;
    padding: var(--spacer-3xs) var(--spacer-lg);

    & .copyright-text {
      color: var(--color-white);

      @mixin text-sm;
    }

    @media (--screen-xs) {
      padding: var(--spacer-3xs) var(--spacer-2xs);
    }
  }
}
</style>
