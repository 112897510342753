import { toRef } from 'vue'
import { useHydrationStore } from '#imports'

type SortingOptionInfo = {
  code: string
  nameMap: string[][]
}

export type SortingOption = [ string, SortingOptionInfo ]

type State = {
  counter: number
}

export function useMainStore () {
  const state = useHydrationStore<State>('main-store', {
    counter: 0
  })

  function openModalCity () {
    const { openModal } = useModals()
    openModal('city-select')
  }

  return {
    openModalCity,

    counter: toRef(state.value, 'counter')
  }
}
