import { useContextData } from '#imports'
import type { SocialType } from '@/components/atoms/Social/types'
import { useLocalization } from '@/modules/nuxt-localization/composables'
import { useCategories } from '@/stores/categories'
import type { IconName } from '@/utils/icon-types'
import type { InterestingSection } from '@/modules/nuxt-api/models/InterestingSection'
import type { SectionGroup } from '@/modules/nuxt-api/models/SectionGroup'
export type FooterLink = {
  title: string
  href?: string
  isFullPath?: boolean
  badge?: string
  isEndWithSlash?: boolean
  class?: string
  emit?: 'open:shop' | 'open:cityModal' | 'toggle'
  logo?: SocialType
}

export type FooterSection = {
  heading: string
  links: FooterLink[]
}

export function useFooter () {
  const { getInterestingSections, getNavigationMenu } = useCategories()
  const { getUrl } = useNavigate()

  async function getInteresting () {
    try {
      const interestingSections = await getInterestingSections()

      if (!interestingSections?.length) {
        return []
      }

      const res: InterestingSection[] = interestingSections?.map((section: any) => ({
        title: section?.title,
        href: section?.section?.path?.path
      }))

      return res
    } catch (err) {
      return []
    }
  }

  async function getFooterCategories () {
    const menu = await getNavigationMenu()

    if (!menu?.sectionGroupsList?.length) {
      return []
    }

    const categories = menu.sectionGroupsList
    const res: SectionGroup['sectionsList'] = []

    for (let i = 0; i < categories.length; i++) {
      if (categories[i].displayType === 2) {
        continue
      }

      if (categories[i].displayType === 3) {
        res.push(categories[i])
      }

      res.push(...(categories[i].sectionsList?.filter(el => el.displayType !== 2) ?? []))
    }

    return res
  }

  async function getFooterLinks (isMsc: boolean) {
    const { t } = useLocalization()
    const links = useContextData<FooterSection[]>('footer-links', [])

    const offerLinks = await Promise.all([
      getInteresting(),
      getFooterCategories().then((res) => {
        return res.map(el => ({
          title: el.title,
          href: getUrl(el.path?.path || '')
        }))
      })
    ])

    if (links.value.length === 0) {
      links.value = [
        {
          heading: t('organisms.footer.buyers'),
          links: [
            {
              title: t('organisms.footer.buy-sell'),
              href: '/pages/delivery.html'
            },
            {
              title: t('organisms.footer.guarantees'),
              href: '/pages/warranty.html'
            },
            {
              title: t('organisms.footer.contacts'),
              href: '/pages/contacts.html'
            }
            // ...(isMsc
            //   ? [{
            //       title: t('organisms.footer.buy'),
            //       href: '/rush_buy/'
            //     }]
            //   : []),
            // ...(isMsc
            //   ? [{
            //       title: t('organisms.footer.wines'),
            //       href: '/pages/wine_tasting.html'
            //     }]
            //   : [])
          ]
        },
        {
          heading: t('organisms.footer.business-clients'),
          links: [
            {
              title: t('organisms.footer.corp-clients'),
              href: '/pages/corporate.html'
            },
            {
              title: t('organisms.footer.franchise'),
              href: '/pages/franchise.html',
              badge: 'New'
            },
            {
              title: t('organisms.footer.rest-shops'),
              href: '/opt/',
              isEndWithSlash: true
            },
            ...(isMsc
              ? [
                  {
                    title: t('organisms.footer.lessors'),
                    href: '/pages/for_lessors.html'
                  },
                  // { title: t('organisms.footer.partners'), href: '/pages/for_investors.html' },
                  // { title: t('organisms.footer.importers'), href: '/pages/for_investors.html' },
                  {
                    title: t('organisms.footer.suppliers'),
                    href: '/pages/suppliers.html'
                  }
                ]
              : [
                  {
                    title: t('organisms.footer.suppliers'),
                    href: '/pages/suppliers.html'
                  }
                ]
            )
            // ...(isMsc
            //   ? [{
            //       title: t('organisms.footer.region'),
            //       href: '/pages/reg_partnership.html'
            //     }]
            //   : [])

          ]
        },
        {
          heading: t('organisms.footer.offer'),
          links: [
            ...offerLinks.flat()
          ]
        },
        {
          heading: t('organisms.footer.winestyle'),
          links: [
            {
              title: t('organisms.footer.about'),
              href: '/pages/about.html'
            },
            {
              title: t('organisms.footer.one-day'),
              href: '/pages/oneday.html'
            },
            ...(isMsc
              ? [{
                  title: t('organisms.footer.jobs'),
                  href: '/pages/vacancy/',
                  isEndWithSlash: true
                }]
              : [])
            // ...(isMsc
            //   ? [{
            //       title: t('organisms.footer.charity'),
            //       href: '/pages/charity',
            //       isEndWithSlash: true
            //     }]
            //   : []),
            // ...(isMsc
            //   ? [{
            //       title: t('organisms.footer.blog'),
            //       href: '/articles/',
            //       isEndWithSlash: true
            //     }]
            //   : [])
          ]
        }
      ]
    }

    return links.value
  }

  function getSocialLinks () {
    return [
      {
        icon: 'social-whatsapp-grey' as IconName,
        href: 'https://api.whatsapp.com/send/?phone=74997035151&text&type=phone_number&app_absent=0'
      },
      {
        icon: 'social-telegram-grey' as IconName,
        href: 'https://t.me/winestyle_wow_bot'
      },
      {
        icon: 'social-vk-grey' as IconName,
        href: 'https://vk.com/winestyle'
      }
    ]
  }

  return {
    getSocialLinks,
    getFooterLinks
  }
}
