import { defineAsyncComponent, defineComponent, createStaticVNode, h } from 'vue'

export default defineComponent({
  name: 'ServerRender',
  props: {
    is: {
      type: String,
      required: true
    }
  },
  setup (props, { slots }) {
    const instance = getCurrentInstance()

    if (import.meta.client) {
      const html = instance?.proxy?.$el?.innerHTML

      if (html) {
        import(`@/components/nuxt/global/${props.is}/${props.is}.css`)
        return () => createStaticVNode(html, 1)
      }
    }

    const component = defineAsyncComponent(() => /* @vite-ignore */ import((`@/components/nuxt/global/${props.is}/${props.is}.vue`)))
    return () => h(component, slots?.default?.())
  }
})
